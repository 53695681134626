import React, { useEffect, useState } from 'react';
import './styles.scss';
import { Link, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import fancLogo from '../../assets/images/drawable-xxhdpi/fancLogo.png';
import Mobile from '../../assets/images/drawable-xxhdpi/mobile_menu.png';
import MobileClose from '../../assets/images/drawable-xxhdpi/mobile_close.png';
import Twitter from '../../assets/images/drawable-xxhdpi/twitter.png';
import Telegram from '../../assets/images/drawable-xxhdpi/telegram.png';
import Medium from '../../assets/images/drawable-xxhdpi/medium.png';

export default function Top({ location }) {
  const [isOpen, setIsOpen] = useState(false);
  let navigate = useNavigate();
  const [cookies, setCookie] = useCookies(['lang']);

  useEffect(() => {
    if (cookies.lang === undefined) {
      setCookie('lang', 'EN', { path: '/' });
    }
  }, [cookies]);

  const setLang = (lang) => {
    setCookie('lang', lang, { path: '/' });
  };

  return (
    <div className='top-wrap'>
      <div className='body'>
        <div className='logo'>
          <Link to='/'>
            <img src={fancLogo} alt='' />
          </Link>
        </div>
        <div className='menu'>
          <Link
            to='/about'
            className={
              location &&
              (location.pathname === '/' || location.pathname === '/about')
                ? 'active'
                : ''
            }
          >
            About
          </Link>
          <Link
            to='/platform'
            className={
              location &&
              (location.pathname === '/' || location.pathname === '/platform')
                ? 'active'
                : ''
            }
          >
            Platform
          </Link>
          <Link
            to='/product'
            className={
              location &&
              (location.pathname === '/' || location.pathname === '/product')
                ? 'active'
                : ''
            }
          >
            Product
          </Link>
        </div>
        <div className='lang'>
          <button
            onClick={() => setLang('KO')}
            className={cookies.lang === 'KO' ? 'active' : ''}
          >
            Kor
          </button>
          <button
            onClick={() => setLang('EN')}
            className={cookies.lang === 'EN' ? 'active' : ''}
          >
            Eng
          </button>
        </div>
        <div className='mobile'>
          <button onClick={() => setIsOpen(true)}>
            <img src={Mobile} />
          </button>
        </div>
      </div>
      {isOpen && (
        <div className='mobile-menu'>
          <div className='body'>
            <div className='top'>
              <div className='logo'>
                <button
                  onClick={() => {
                    navigate('/');
                    setIsOpen(false);
                  }}
                >
                  <img src={fancLogo} alt='' />
                </button>
              </div>
              <div className='menu'>
                <button onClick={() => setIsOpen(false)}>
                  <img src={MobileClose} />
                </button>
              </div>
            </div>
            <div className='lang'>
              <button
                onClick={() => {
                  setLang('KO');
                  setIsOpen(false);
                }}
                className={cookies.lang === 'KO' ? 'active' : ''}
              >
                Kor
              </button>
              <span>|</span>
              <button
                onClick={() => {
                  setLang('EN');
                  setIsOpen(false);
                }}
                className={cookies.lang === 'EN' ? 'active' : ''}
              >
                Eng
              </button>
            </div>
            <div className='menu'>
              <a
                onClick={() => {
                  navigate('/about');
                  setIsOpen(false);
                }}
              >
                About
              </a>
              <a
                onClick={() => {
                  navigate('/platform');
                  setIsOpen(false);
                }}
              >
                Platform
              </a>
              <a
                onClick={() => {
                  navigate('/product');
                  setIsOpen(false);
                }}
              >
                Product
              </a>
            </div>
            <div className='sns'>
              <a
                className='twitter'
                target='_blank'
                href='https://twitter.com/FANCNFT'
                rel='noreferrer'
              >
                <img src={Twitter} />
              </a>
              <a
                className='telegram'
                target='_blank'
                href='https://t.me/fanc_official'
                rel='noreferrer'
              >
                <img src={Telegram} />
              </a>
              <a
                className='medium'
                target='_blank'
                href='https://medium.com/@fanc'
                rel='noreferrer'
              >
                <img src={Medium} />
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
