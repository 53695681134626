import React from 'react';
import './styles.scss';
import { useCookies } from 'react-cookie';
import Product01 from '../../assets/images/drawable-xxhdpi/product01.png';
import Product02 from '../../assets/images/drawable-xxhdpi/product02.png';
import Product03 from '../../assets/images/drawable-xxhdpi/product03.png';
import Product04 from '../../assets/images/drawable-xxhdpi/product04.png';
import Product05 from '../../assets/images/drawable-xxhdpi/product05.png';

import Product06 from '../../assets/images/drawable-xxhdpi/product_architecture.png';
import Product07 from '../../assets/images/drawable-xxhdpi/product_bridge_wallet.png';
import Product08 from '../../assets/images/drawable-xxhdpi/product_governance.png';
import Product06EN from '../../assets/images/drawable-xxhdpi/product_architecture_en.png';
import Product07EN from '../../assets/images/drawable-xxhdpi/product_bridge_wallet_en.png';
import Product08EN from '../../assets/images/drawable-xxhdpi/product_governance_en.png';

import Sub01 from '../../assets/images/drawable-xxhdpi/product_sub01.png';
import Sub02 from '../../assets/images/drawable-xxhdpi/product_sub02.png';
import Sub03 from '../../assets/images/drawable-xxhdpi/product_sub03.png';

import { VIDEO_URL } from '../../assets/urls';

export default function Product() {
  const [cookies] = useCookies(['lang']);
  return (
    <div className='product-wrap'>
      <div className='top'>
        <video autoPlay={true} loop muted playsInline controls={false}>
          <source src={VIDEO_URL} />
        </video>
        <h1 className='big-title fade-in'>Product</h1>
        <div className='info'>
          <div className='left'>
            <h1>NFT MARKET PLACE</h1>
            <pre>
              {cookies.lang === 'EN'
                ? `Typical NFT marketplace functions such as the creation 
and sale of NFT content are provided. NFTs can be created, 
bought, and sold by anyone, and NFTs created by creators 
become blockchain-based digital content. Using 
the fanC NFT Marketplace, users can easily create images, 
videos, audio files, etc. as NFT content without 
the need for any professional knowledge. Users can interact 
with influencers, participate in fandom activities, 
and earn creator rewards through the fanC NFT Marketplace.`
                : `일반적인 NFT 마켓 플레이스의 NFT 콘텐츠 생성, 구매 및 판매 
거래 기능을 제공합니다. 누구나 쉽게 NFT를 생성, 구매, 판매할 수 있으며,
크리에이터가 생성한 NFT는 블록체인 기반의 디지털 콘텐츠가 됩니다.
사용자는 fanC NFT 마켓플레이스에서 필요에 따라 전문적인 지식 없이도 
이미지, 동영상, 음성 파일 등을 NFT 콘텐츠로 쉽게 생성할 수 있습니다. 
fanC NFT 마켓 플레이스를 통해 사용자는 인플루언서와 
직접적인 콘텐츠 교류를 할 수 있고, 팬덤 활동을 즐기거나 
내가 크리에이터가 되어 보상을 획득할 수 있습니다.`}
            </pre>
          </div>
          <div className='right'>
            <div className='product top'>
              <img src={Product01} alt='' />
            </div>
          </div>
        </div>
        <div className='info'>
          <div className='left'>
            <div className='product bottom'>
              <img src={Product02} alt='' />
            </div>
          </div>
          <div className='right'>
            <h1>FANC MYSTERY BOX</h1>
            <pre>
              {cookies.lang === 'EN'
                ? `The fanC NFT Mystery Box sells Mystery Box, 
a content product planned by the fanC team. 
It provides users the opportunity to open 
or resell their Mystery Boxes. 
It is a concept to acquire the probability NFT 
by grade introduced on the sales page 
at the time of sale when you open the Mystery Box. 
Using the fanC team's expertise, 
Mystery Boxes for influencers, blockchain game items, 
and CELEBe avatars will be launched. `
                : `fanC NFT Mystery Box는 fanC 팀에서 
기획한 콘텐츠 Mystery Box를 판매하고, 
사용자는 구매한 Mystery Box를 개봉하거나 
재판매할 수 있는 NFT 마켓 플레이스를 제공받을 수 있습니다.
Mystery Box를 개봉하면, 판매 당시 판매 페이지에서 
소개한 등급별 확률형 NFT를 획득하는 개념입니다. 
fanC 팀과 협업을 통해 파트너사가 발행하는 
Mystery Box가 런칭될 것이며, 인플루언서, 
블록체인 게임 아이템, CELEBe AVATAR 등 
다양한 분야의 Mystery Box를 제공할 예정입니다.`}
            </pre>
          </div>
        </div>
        <h1 className='middle-title'>FANCSIDECHAIN</h1>
        <div className='info pc'>
          <div className='left'>
            <img src={Sub01} alt='' className='subtitle' />
            <pre>
              {cookies.lang === 'EN'
                ? `The fast transaction speed and near-zero fees 
will make it the first NFT platform with sidechains 
that aren't found on any other NFT platforms. 
Sidechain is an easy wallet-to-wallet connection process 
that does not require users to undergo secondary learning.`
                : `거래를 처리하는 데 1초밖에 걸리지 않는 빠른 속도와 
제로에 가까운 수수료는 다른 NFT 플랫폼에서 찾아볼 수 
없는 사이드 체인을 적용한 NFT 플랫폼의 최초 사례가 
될 것입니다. Sidechain을 사용하기 위해 사용자는 
2차 학습을 진행하지 않아도 되며, 기본적으로 사용하는 
월렛 대 월렛의 연결 과정으로 간편하게 사용할 수 있습니다.`}
            </pre>
          </div>
          <div className='right'>
            <img src={Product03} alt='' className='product first' />
          </div>
        </div>
        <div className='info pc'>
          <div className='left'>
            <img src={Sub02} alt='' className='subtitle' />
            <pre>
              {cookies.lang === 'EN'
                ? `The fanC Bridge & Wallet operates as a sidechain 
of the Ethereum Network, which is being developed 
independently for the fanC platform. 
Sidechains are blockchains that are compatible 
with the existing Mainchain and they use their own 
transactions to operate independently from it.`
                : `fanC Bridge & Wallet은 fanC 플랫폼을 위해 
독자적으로 제작하고 있는 Ethereum Network의 
Sidechain으로 작동하는 모델입니다. Sidechain은 
기존의 Mainchain과 병렬로 작동하고 호환되도록 
설계된 블록체인으로, 자체 트랜잭션을 활용하여 
Mainchain과 독립적으로 작동하는 것입니다.`}
            </pre>
          </div>
          <div className='right'>
            <img src={Product04} alt='' className='product second' />
          </div>
        </div>
        <div className='info pc'>
          <div className='left'>
            <img src={Sub03} alt='' className='subtitle' />
            <pre>
              {cookies.lang === 'EN'
                ? `FanC aims to be a decentralized platform 
that allows users and communities to manage 
and decide policies through voting. 
Early fans of the fanC NFT Marketplace can airdrop 
Fancy Tokens (fanC) and participate in Governance 
by purchasing Fancy Tokens (fanC) on decentralized 
or general exchanges.`
                : `fanC 플랫폼은 사용자와 커뮤니티가 제안하는 
정책에 대해 투표로 관리하고 결정하는 탈중앙화 
플랫폼으로의 발전을 목표로 합니다.
초기 fanC NFT 마켓 플레이스의 활성 사용자는 
팬시토큰(fanC)을 에어드랍 받을 수 있으며, 
탈중앙화 거래소나 일반 거래소에서 팬시토큰(fanC)을 
구매하여 Governance에 참여할 수 있습니다.`}
            </pre>
          </div>
          <div className='right'>
            <img src={Product05} alt='' className='product third' />
          </div>
        </div>
        <div className='info mobile'>
          <img src={cookies.lang === 'EN' ? Product06EN : Product06} alt='' />
          <img src={cookies.lang === 'EN' ? Product07EN : Product07} alt='' />
          <img src={cookies.lang === 'EN' ? Product08EN : Product08} alt='' />
        </div>
      </div>
    </div>
  );
}
