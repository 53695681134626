import React from 'react';
import './styles.scss';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import fancLogo from '../../assets/images/drawable-xxhdpi/fancLogo_w.png';

export default function Footer() {
  const [cookies] = useCookies(['lang']);
  return (
    <div className='footer-wrap'>
      <div className='body'>
        <div className='left'>
          <Link to='/'>
            <img src={fancLogo} alt='' />
          </Link>
          {cookies.lang === 'EN' ? (
            <p className='info'>
              As part of our virtuous cycle structure, <br />
              we will strengthen the rewards of creators and users <br />
              for short-form content platform activities, <br />
              thereby nurturing new creators and activating <br />
              various NFT content creation activities.
            </p>
          ) : (
            <p className='info'>
              크리에이터, 사용자의 숏폼 콘텐츠 플랫폼 활동 보상을 <br />
              강화한 선순환 구조를 형성해 새로운 크리에이터 육성과 <br />
              다양한 NFT 콘텐츠 창작 활동을 활성화시키겠습니다.
            </p>
          )}
          <p className='copy'>&copy; 2022 fanC All rights reserved.</p>
        </div>
        <div className='right'>
          <div className='contact-wrap'>
            <p className='title'>Contact</p>
            <p className='sub-title'>General Information</p>
            <a className='email' href='mailto:contact@fanc.io'>
              contact@fanc.io
            </a>
            <br className='mobile' />
            <p className='sub-title'>CELEBe</p>
            <a className='email' href='mailto:cs@celebe.io'>
              cs@celebe.io
            </a>
            <br className='mobile' />
            <p className='sub-title'>fancplace</p>
            <a className='email' href='mailto:support@fancplace.io'>
              support@fancplace.io
            </a>
            <p className='copy'>&copy; 2022 fanC All rights reserved.</p>
          </div>
          <div className='sns-wrap'>
            <a
              className='twitter'
              target='_blank'
              href='https://twitter.com/fanC_Official'
              rel='noreferrer'
            ></a>
            <a
              className='telegram'
              target='_blank'
              href='https://t.me/fanc_official'
              rel='noreferrer'
            ></a>
            <a
              className='medium'
              target='_blank'
              href='https://medium.com/@fanc'
              rel='noreferrer'
            ></a>
          </div>
        </div>
      </div>
    </div>
  );
}
