import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './Home/Home';
import About from './About/About';
import Platform from './Platform/Platform';
import Product from './Product/Product';
import Top from '../components/Top';
import Footer from '../components/Footer';

export const Paths = {
  Home: '/',
  About: '/about',
  Platform: '/platform',
  Product: '/product',
};

const Router = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div>
      <Top location={location} />
      <div className='body-wrap'>
        <Routes>
          <Route path={Paths.Home} element={<Home />} />
          <Route path={Paths.About} element={<About />} />
          <Route path={Paths.Platform} element={<Platform />} />
          <Route path={Paths.Product} element={<Product />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default Router;
