import React, { useState } from 'react';
import './styles.scss';
import { useCookies } from 'react-cookie';

import Roadmap01 from '../../assets/images/drawable-xxhdpi/roadmap_1.png';
import Roadmap02 from '../../assets/images/drawable-xxhdpi/roadmap_2.png';
import Roadmap03 from '../../assets/images/drawable-xxhdpi/roadmap_3.png';
import Roadmap04 from '../../assets/images/drawable-xxhdpi/roadmap_4.png';
import Roadmap05 from '../../assets/images/drawable-xxhdpi/roadmap_5.png';
import Roadmap06 from '../../assets/images/drawable-xxhdpi/roadmap_6.png';
import Roadmap07 from '../../assets/images/drawable-xxhdpi/roadmap_7.png';
import Roadmap08 from '../../assets/images/drawable-xxhdpi/roadmap_8.png';
import Roadmap09 from '../../assets/images/drawable-xxhdpi/roadmap_9.png';
import Roadmap01En from '../../assets/images/drawable-xxhdpi/roadmap_1_en.png';
import Roadmap02En from '../../assets/images/drawable-xxhdpi/roadmap_2_en.png';
import Roadmap03En from '../../assets/images/drawable-xxhdpi/roadmap_3_en.png';
import Roadmap04En from '../../assets/images/drawable-xxhdpi/roadmap_4_en.png';
import Roadmap05En from '../../assets/images/drawable-xxhdpi/roadmap_5_en.png';
import Roadmap06En from '../../assets/images/drawable-xxhdpi/roadmap_6_en.png';
import Roadmap07En from '../../assets/images/drawable-xxhdpi/roadmap_7_en.png';
import Roadmap08En from '../../assets/images/drawable-xxhdpi/roadmap_8_en.png';
import Roadmap09En from '../../assets/images/drawable-xxhdpi/roadmap_9_en.png';
import Circle from '../../assets/images/drawable-xxhdpi/roadmap_circle.png';
import ArrowDefault from '../../assets/images/drawable-xxhdpi/roadmap_default_arrow.png';
import ArrowNext from '../../assets/images/drawable-xxhdpi/roadmap_next_arrow.png';
import RoadmapMobileClose from '../../assets/images/drawable-xxhdpi/roadmap_default_mobile.png';
import RoadmapMobileOpen from '../../assets/images/drawable-xxhdpi/roadmap_mobile.png';
import RoadmapMobileCloseEN from '../../assets/images/drawable-xxhdpi/roadmap_default_mobile_en.png';
import RoadmapMobileOpenEN from '../../assets/images/drawable-xxhdpi/roadmap_mobile_en.png';
import MoreBtn from '../../assets/images/drawable-xxhdpi/roadmap_more_btn.png';
import SkipBtn from '../../assets/images/drawable-xxhdpi/roadmap_skip_btn.png';

export default function Roadmap() {
  const [isOpen, setIsOpen] = useState(false);
  const [x, setX] = useState(0);
  const [cookies] = useCookies(['lang']);
  const onScroll = (e) => {
    const P = (e.target.offsetWidth - 100) / 4;
    setX(parseInt(e.target.scrollLeft / P));
  };
  return (
    <div className='roadmap-wrap'>
      <div className='pc'>
        <div className='body'>
          <div className='line'>
            <img src={x > 0 ? ArrowNext : ArrowDefault} alt='' />
          </div>
          <div className='page-wrap' onScroll={onScroll}>
            <img src={cookies.lang === 'EN' ? Roadmap01En : Roadmap01} alt='' />
            <img src={cookies.lang === 'EN' ? Roadmap02En : Roadmap02} alt='' />
            <img src={cookies.lang === 'EN' ? Roadmap03En : Roadmap03} alt='' />
            <img src={cookies.lang === 'EN' ? Roadmap04En : Roadmap04} alt='' />
            <img src={cookies.lang === 'EN' ? Roadmap05En : Roadmap05} alt='' />
            <img src={cookies.lang === 'EN' ? Roadmap06En : Roadmap06} alt='' />
            <img src={cookies.lang === 'EN' ? Roadmap07En : Roadmap07} alt='' />
            <img src={cookies.lang === 'EN' ? Roadmap08En : Roadmap08} alt='' />
            <img src={cookies.lang === 'EN' ? Roadmap09En : Roadmap09} alt='' />
          </div>
        </div>
        <div className='navigation-wrap'>
          <div className='line' />
          <div className='mark-wrap'>
            <div className='mark'>
              <div className='circle-wrap'>
                <div className='white' />
                <img src={Circle} alt='' />
              </div>
              <p className='label'>21,1Q - 21,3Q</p>
            </div>
            <div className='mark'>
              <div className='circle-wrap'>
                <div className='white' />
                <img src={Circle} alt='' />
              </div>
              <p className='label'>21,4Q</p>
            </div>
            <div className='mark'>
              <div className='circle-wrap'>
                <div className='white' />
                <img src={Circle} alt='' />
              </div>
              <p className='label'>22,1Q</p>
            </div>
            <div className='mark'>
              <div className='circle-wrap'>
                <div className='white' />
                <img src={Circle} alt='' />
              </div>
              <p className='label'>22,2Q</p>
            </div>
            <div className='mark'>
              <div className='circle-wrap'>
                <div className='white' />
                {x > 0 ? <img src={Circle} alt='' /> : null}
              </div>
              <p className='label'>22,3Q</p>
            </div>
            <div className='mark'>
              <div className='circle-wrap'>
                <div className='white' />
                {x > 1 ? <img src={Circle} alt='' /> : null}
              </div>
              <p className='label'>23,1Q</p>
            </div>
            <div className='mark'>
              <div className='circle-wrap'>
                <div className='white' />
                {x > 2 ? <img src={Circle} alt='' /> : null}
              </div>
              <p className='label'>23,2Q</p>
            </div>
            <div className='mark'>
              <div className='circle-wrap'>
                <div className='white' />
                {x > 3 ? <img src={Circle} alt='' /> : null}
              </div>
              <p className='label'>23,3Q</p>
            </div>
            <div className='mark'>
              <div className='circle-wrap'>
                <div className='white' />
                {x > 4 ? <img src={Circle} alt='' /> : null}
              </div>
              <p className='label'>23,4Q</p>
            </div>
          </div>
        </div>
      </div>
      <div className='mobile'>
        {cookies.lang === 'EN' ? (
          <img
            src={isOpen ? RoadmapMobileOpenEN : RoadmapMobileCloseEN}
            alt=''
          />
        ) : (
          <img src={isOpen ? RoadmapMobileOpen : RoadmapMobileClose} alt='' />
        )}
        <button onClick={() => setIsOpen(!isOpen)}>
          <img src={isOpen ? SkipBtn : MoreBtn} alt='' />
        </button>
      </div>
    </div>
  );
}
