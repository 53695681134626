import React from 'react';
import './App.scss';
import Router from './pages/Router';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';

function App() {
  return (
    <CookiesProvider>
      <BrowserRouter>
        <div className='App'>
          <Router />
        </div>
      </BrowserRouter>
    </CookiesProvider>
  );
}

export default App;
