import React from 'react';
import './styles.scss';
import { useCookies } from 'react-cookie';
import fancLogo from '../../assets/images/drawable-xxhdpi/fancLogo_big.png';
import { WHITEPAPER_KOR_URL, WHITEPAPER_ENG_URL } from '../../assets/urls';
import { VIDEO_URL } from '../../assets/urls';

export default function Home() {
  const [cookies] = useCookies(['lang']);
  return (
    <div className='home-wrap'>
      <video autoPlay={true} loop muted playsInline controls={false}>
        <source src={VIDEO_URL} />
      </video>
      <div className='center'>
        <img src={fancLogo} alt='' />
        {cookies.lang === 'EN' ? (
          <p>
            As part of our virtuous cycle structure, we will strengthen the
            rewards of creators and users for short-form content platform
            activities, thereby nurturing new creators and activating various
            NFT content creation activities.
          </p>
        ) : (
          <p>
            크리에이터, 사용자의 숏폼 콘텐츠 플랫폼 활동 보상을 강화한 선순환
            구조를 형성해 새로운 크리에이터 육성과 다양한 NFT 콘텐츠 창작 활동을
            활성화시키겠습니다.
          </p>
        )}
        <a
          target='_blank'
          href={cookies.lang === 'EN' ? WHITEPAPER_ENG_URL : WHITEPAPER_KOR_URL}
          rel='noreferrer'
        >
          WHITEPAPER
        </a>
      </div>
    </div>
  );
}
