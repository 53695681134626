import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import Lottie from 'react-lottie';
import { useCookies } from 'react-cookie';
import './styles.scss';
import Fire from '../../assets/fanc_coin.json';

import Title from '../../assets/images/drawable-xxhdpi/platform_title.png';
import Jisoo from '../../assets/images/drawable-xxhdpi/platform_jisoo.png';
import WteFaker from '../../assets/images/drawable-xxhdpi/platform_wte_main.png';
import Wte01 from '../../assets/images/drawable-xxhdpi/platform_wte_1.png';
import Wte02 from '../../assets/images/drawable-xxhdpi/platform_wte_2.png';
import Wte03 from '../../assets/images/drawable-xxhdpi/platform_wte_3.png';
import Wte04 from '../../assets/images/drawable-xxhdpi/platform_wte_4.png';
import Wte05 from '../../assets/images/drawable-xxhdpi/platform_wte_5.png';

import Celeb from '../../assets/images/drawable-xxhdpi/platform_celeb.png';

import Celebs from '../../components/Celebs';
import { VIDEO_URL } from '../../assets/urls';

export default function Platform() {
  const [ref, inView] = useInView();
  const [ani, setAni] = useState(false);
  const [cookies] = useCookies(['lang']);
  useEffect(() => {
    if (inView) {
      setAni(true);
    }
  }, [inView]);

  return (
    <div className='platform-wrap'>
      <div className='top'>
        <video autoPlay={true} loop muted playsInline controls={false}>
          <source src={VIDEO_URL} />
        </video>
        <h1 className='big-title fade-in'>Platform</h1>
        <img src={Title} alt='' className='image-title' />
        <div className='info'>
          <div className='left'>
            <div className='jisoo'>
              <img src={Jisoo} alt='' />
              <p>
                {cookies.lang === 'EN'
                  ? 'CELEBe Model `BLACKPINK JISOO`'
                  : 'CELEBe 메인 모델 `BLACKPINK 지수`'}
              </p>
            </div>
          </div>
          <div className='right'>
            <h1>[ Short Form ]</h1>
            <pre>
              {cookies.lang === 'EN'
                ? `In a short form content market dominated 
by large global platforms, CELEBe is spreading 
its influence as 'Korean short form'. 
In collaboration with fanC, CELEBe provides 
metaverse, AR/VR, and avatar functions, bringing 
Korean content creators together and fostering them, 
while developing the influence of Korean content platforms.`
                : `'CELEBe'는 '숏폼 콘텐츠 플랫폼'으로써
최근 글로벌 대형 플랫폼들이 선점하고 있는 숏폼 콘텐츠 시장에서 
'코리안숏폼'으로 영향력을 확산시키고 있습니다.
fanC 플랫폼과 함께 CELEBe가 최종 목표로 하는
메타버스, AR/VR, 아바타 기능 등을 구성하고,
크리에이터를 한 곳에 모아 풍부한 콘텐츠를 생산, 제공해
크리에이터 육성 및 한국 콘텐츠 IP 보호에 앞장섭니다.`}
            </pre>
          </div>
        </div>
        <div className='info'>
          <div className='left'>
            <h1>[ Watch to Earn]</h1>
            <div className='sub-info'>
              <p>Watch to Earn</p>
              <span>
                {cookies.lang === 'EN'
                  ? 'Through Connected Points (CP), CELEBe rewards its users. The platform not only rewards creators who produce short-form content, but also users who watch it.'
                  : 'CELEBe에서 활동하는 모든 사용자에게 Connected Point(CP)를 통해 보상을 지급합니다. 숏폼 콘텐츠를 생산하는 크리에이터뿐만 아니라 콘텐츠를 시청하는 사용자에게도 보상을 지급하여 플랫폼 내 선순환 구조를 형성합니다.'}
              </span>
            </div>
            <div className='sub-info'>
              <p>Create to Earn</p>
              <span>
                {cookies.lang === 'EN'
                  ? '`STEPPING STONE` provides artists with support from creative activities to marketing. The platform also provides a marketplace where you can trade various NFT works and Fandom NFT anytime.'
                  : '`STEPPING STONE` 서비스를 통해 작가들의 창작 활동부터 마케팅 전반적인 부분을 지원합니다. 또한 마켓 플레이스를 제공하여 다양한 NFT 작품들과 Fandoms NFT를 상시 거래할 수 있습니다.'}
              </span>
            </div>
          </div>
          <div className='right'>
            <div className={ani ? 'wte ani' : 'wte'}>
              <img src={Wte01} alt='' className='other' />
              <img src={Wte02} alt='' className='other' />
              <img src={Wte03} alt='' className='other' />
              <img src={Wte04} alt='' className='other' />
              <img src={Wte05} alt='' className='other' />
              <img src={WteFaker} alt='' className='faker' />
              {ani ? (
                <Lottie
                  options={{
                    animationData: Fire,
                    loop: false,
                    autoplay: true,
                  }}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '200%',
                  }}
                />
              ) : null}
            </div>
          </div>
        </div>
        <div className='info' ref={ref}>
          <div className='left'>
            <div className='celeb'>
              <img src={Celeb} alt='' />
              <p>
                {cookies.lang === 'EN'
                  ? 'CELEBe Model ‘Holybang Honey.j, T1 FAKER’'
                  : 'CELEBe 활동 모델 왼쪽부터 ‘Holybang 허니제이, T1 FAKER’'}
              </p>
            </div>
          </div>
          <div className='right'>
            <h1>[ Celeb ]</h1>
            <pre>
              {cookies.lang === 'EN'
                ? `Among the 4000 celebrities with CELEBe are actors, singers, 
broadcasters, announcers, comedians, models, athletes, 
cheerleaders, YouTubers, influencers, and creators.`
                : `배우, 가수, 방송인, 아나운서, 코미디언, 모델, 스포츠선수,
치어리더, 유튜버, 인플루언서, 크리에이터 등 다양한 영역의
셀럽 약 4,000여명이 CELEBe와 함께합니다.
`}
            </pre>
          </div>
        </div>
      </div>
      <Celebs />
    </div>
  );
}
