import React from 'react';
import './styles.scss';

import Celeb from '../../assets/images/drawable-xxhdpi/platform_celebs.png';

export default function Celebs() {
  return (
    <div className='celebs-wrap'>
      <div className='page-wrap'>
        <img src={Celeb} />
      </div>
    </div>
  );
}
