export const FAVICON_URL =
  'https://s3.ap-northeast-2.amazonaws.com/www.fanc.io/favicon.ico';

export const VIDEO_URL =
  'https://s3.ap-northeast-2.amazonaws.com/www.fanc.io/static/media/alpha.05e86ec49bc72a690a52.mp4';

export const WHITEPAPER_ENG_URL =
  'https://fanc-1.gitbook.io/fanc-whitepaper';
export const WHITEPAPER_KOR_URL =
  'https://fanc-1.gitbook.io/fanc-whitepaper/v/fanc-1';
